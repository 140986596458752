<template>
	<router-link :to="{name:'pool.index'}" class="badge badge-danger" data-container="body" data-toggle="popover" data-content="Es un nuevo modelo de inversión que permite a más de una persona participar en un mismo préstamo. Dar click más información" title="POOL" data-placement="top" style="cursor: help;" data-trigger="hover">
		Pool
	</router-link>
</template>
<script type="text/javascript">
	export default {
		mounted(){
			$(this.$el).popover({
				template: `
					<div class="popover" role="tooltip">
						<div class="arrow"></div>
						<h3 class="popover-header text-center font-weight-bold"></h3>
						<div class="popover-body"></div>
					</div>
				`
			})
		}
	}
</script>